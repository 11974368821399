.footable {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border: solid #ccc 1px;

    border-radius: 0;
    font-size: 14px;

}

.footable.breakpoint > tbody > tr > td.expand {
    background: url('../img/plus.png') no-repeat 5px center;
    padding-left: 40px;
}

.footable.breakpoint > tbody > tr.footable-detail-show > td.expand {
    background: url('../img/minus.png') no-repeat 5px center;
}

.footable.breakpoint > tbody > tr.footable-row-detail {
    background: #EBEDEF;
}

.footable > tbody > tr:hover {
    background: #fbf8e9;
}

.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
    cursor: pointer;
}

.footable > tbody > tr > td, .footable > thead > tr > th {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.footable > tbody > tr > td.footable-cell-detail {
    border-left: none;
}

.footable > thead > tr > th, .footable > thead > tr > td {
    background-color: #EBEDED;

    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    border-top: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
}

.footable > thead > tr:first-child > th.footable-first-column, .footable > thead > tr:first-child > td.footable-first-column {
    -moz-border-radius: 0 0;
    -webkit-border-radius: 0 0;
    border-radius: 0 0;
}

.footable > thead > tr:first-child > th.footable-last-column, .footable > thead > tr:first-child > td.footable-last-column {
    -moz-border-radius: 0 0;
    -webkit-border-radius: 0 0;
    border-radius: 0 0;
}

.footable > thead > tr:first-child > th.footable-first-column.footable-last-column, .footable > thead > tr:first-child > td.footable-first-column.footable-last-column {
    -moz-border-radius: 6px 0;
    -webkit-border-radius: 6px 0;
    border-radius: 6px 0;
}

.footable > tbody > tr:last-child > td.footable-first-column {
    -moz-border-radius: 0 0 0 6px;
    -webkit-border-radius: 0 0 0 6px;
    border-radius: 0 0 0 6px;
}

.footable > tbody > tr:last-child > td.footable-last-column {
    -moz-border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;
    border-radius: 0 0 6px 0;
}

.footable > tbody > tr:last-child > td.footable-first-column.footable-last-column {
    -moz-border-radius: 0 0 6px 6px;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
}

.footable > thead > tr > th.footable-first-column, .footable > thead > tr > td.footable-first-column,
.footable > tbody > tr > td.footable-first-column {
    border-left: none;
}

.footable > tbody img {
    vertical-align: middle;
}

.footable > tfoot > tr > th, .footable > tfoot > tr > td {
    background-color: #dce9f9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ebf3fc), to(#dce9f9));
    background-image: -webkit-linear-gradient(top, #ebf3fc, #dce9f9);
    background-image: -moz-linear-gradient(top, #ebf3fc, #dce9f9);
    background-image: -ms-linear-gradient(top, #ebf3fc, #dce9f9);
    background-image: -o-linear-gradient(top, #ebf3fc, #dce9f9);
    background-image: linear-gradient(to bottom, #ebf3fc, #dce9f9);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .8) inset;
    border-top: 1px solid #ccc;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    padding: 10px;
}
