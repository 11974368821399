/* basic scrollbar styling */
/* vertical scrollbar */
.mCSB_container {
    width: auto;
    margin-right: 30px;
    overflow: hidden;
}

.mCSB_container.mCS_no_scrollbar {
    margin-right: 0;
}

.mCS_disabled > .mCustomScrollBox > .mCSB_container.mCS_no_scrollbar,
.mCS_destroyed > .mCustomScrollBox > .mCSB_container.mCS_no_scrollbar {
    margin-right: 30px;
}

.mCustomScrollBox > .mCSB_scrollTools {
    width: 16px;
    height: 100%;
    top: 0;
    right: 0;
}

.mCSB_scrollTools .mCSB_draggerContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 2px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mCSB_scrollTools .mCSB_dragger {
    cursor: pointer;
    width: 100%;
    height: 30px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
    display: block;
    position: relative;
    height: 20px;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
    top: 100%;
    margin-top: -40px;
}

/* horizontal scrollbar */
.mCSB_horizontal > .mCSB_container {
    height: auto;
    margin-right: 0;
    margin-bottom: 30px;
    overflow: hidden;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar {
    margin-bottom: 0;
}

.mCS_disabled > .mCSB_horizontal > .mCSB_container.mCS_no_scrollbar,
.mCS_destroyed > .mCSB_horizontal > .mCSB_container.mCS_no_scrollbar {
    margin-right: 0;
    margin-bottom: 30px;
}

.mCSB_horizontal.mCustomScrollBox > .mCSB_scrollTools {
    width: 100%;
    height: 16px;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.mCSB_horizontal > .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 0 20px;
}

.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    margin: 7px 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger {
    width: 30px;
    height: 100%;
}

.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mCSB_horizontal > .mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_horizontal > .mCSB_scrollTools .mCSB_buttonRight {
    display: block;
    position: relative;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    float: left;
}

.mCSB_horizontal > .mCSB_scrollTools .mCSB_buttonRight {
    margin-left: -40px;
    float: right;
}

.mCustomScrollBox {
    -ms-touch-action: none; /*MSPointer events - direct all pointer events to js*/
}

/* default scrollbar colors and backgrounds (default theme) */
.mCustomScrollBox > .mCSB_scrollTools {
    opacity: 0.75;
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)"; /* old ie */
}

.mCustomScrollBox:hover > .mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; /* old ie */
}

.mCSB_scrollTools .mCSB_draggerRail {
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.4);
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)"; /* old ie */
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.75);
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)"; /* old ie */
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.85);
    filter: "alpha(opacity=85)";
    -ms-filter: "alpha(opacity=85)"; /* old ie */
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.9);
    filter: "alpha(opacity=90)";
    -ms-filter: "alpha(opacity=90)"; /* old ie */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    background-image: url(../mCSB_buttons.png);
    background-repeat: no-repeat;
    opacity: 0.4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)"; /* old ie */
}

.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 0;
    /*
    sprites locations are 0 0/-16px 0/-32px 0/-48px 0 (light) and -80px 0/-96px 0/-112px 0/-128px 0 (dark)
    */
}

.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -20px;
    /*
    sprites locations are 0 -20px/-16px -20px/-32px -20px/-48px -20px (light) and -80px -20px/-96px -20px/-112px -20px/-128px -20px (dark)
    */
}

.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -40px;
    /*
    sprites locations are 0 -40px/-20px -40px/-40px -40px/-60px -40px (light) and -80px -40px/-100px -40px/-120px -40px/-140px -40px (dark)
    */
}

.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -56px;
    /*
    sprites locations are 0 -56px/-20px -56px/-40px -56px/-60px -56px (light) and -80px -56px/-100px -56px/-120px -56px/-140px -56px (dark)
    */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
    opacity: 0.75;
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)"; /* old ie */
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
    opacity: 0.9;
    filter: "alpha(opacity=90)";
    -ms-filter: "alpha(opacity=90)"; /* old ie */
}

/*scrollbar themes*/
/*dark (dark colored scrollbar)*/
.mCS-dark > .mCSB_scrollTools .mCSB_draggerRail {
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.15);
}

.mCS-dark > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.75);
}

.mCS-dark > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.85);
}

.mCS-dark > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.9);
}

.mCS-dark > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

/*light-2*/
.mCS-light-2 > .mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.75);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-light-2.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-light-2.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.85);
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2 > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.9);
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px 0;
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -20px;
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -40px;
}

.mCS-light-2 > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -56px;
}

/*dark-2*/
.mCS-dark-2 > .mCSB_scrollTools .mCSB_draggerRail {
    width: 8px;
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 8px;
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.75);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    height: 8px;
    margin: 6px 0;
}

.mCS-dark-2.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 8px;
    margin: 6px auto;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2 > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px 0;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -20px;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -40px;
}

.mCS-dark-2 > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -56px;
}

/*light-thick*/
.mCS-light-thick > .mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.75);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-light-thick.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-light-thick.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 6px;
    margin: 5px auto;
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -16px 0;
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -16px -20px;
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -20px -40px;
}

.mCS-light-thick > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -20px -56px;
}

/*dark-thick*/
.mCS-dark-thick > .mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.75);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-dark-thick.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 6px;
    margin: 5px auto;
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -96px 0;
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -96px -20px;
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -40px;
}

.mCS-dark-thick > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -56px;
}

/*light-thin*/
.mCS-light-thin > .mCSB_scrollTools .mCSB_draggerRail {
    background: #fff; /* rgba fallback */
    background: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
}

.mCS-light-thin.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
}

.mCS-light-thin.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 2px;
    margin: 7px auto;
}

/*dark-thin*/
.mCS-dark-thin > .mCSB_scrollTools .mCSB_draggerRail {
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
    background: #000; /* rgba fallback */
    background: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_horizontal > .mCSB_scrollTools .mCSB_draggerRail {
    width: 100%;
}

.mCS-dark-thin.mCSB_horizontal > .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 2px;
    margin: 7px auto;
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin > .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark-thin > .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}