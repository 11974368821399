.minict_wrapper {
    font-family: "Segoe UI", Segoe, "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    color: #333;
    background: #fff;
    position: relative;
    width: 300px;
    height: 2.2rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.minict_wrapper * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.minict_wrapper:after {
    content: "\25BC";
    display: block;
    position: absolute;
    height: 2.1rem;
    width: 2.1rem;
    top: 0;
    right: 0;
    font-size: 0.6rem;
    line-height: 1.9rem;
    text-align: center;
    color: #555;
}

.minict_wrapper.active:after {
    content: "\25B2";
}

.minict_wrapper input {
    border: 0;
    outline: none;
    background: none;
    font-family: "Segoe UI", Segoe, "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    color: #333;
    font-size: 1rem;
    height: 1.9rem;
    padding: 0 2.2rem 0 0.4rem;
    cursor: default;
    width: 100%;
}

.minict_wrapper ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    top: 2rem;
    left: 0;
    max-height: 22rem;
    overflow-y: auto;
    z-index: 999999;
}

.minict_wrapper ul li {
    list-style: none;
    font-size: 1rem;
    padding: 0 0.7rem;
    cursor: pointer;
    background: #fff;
    height: 2.2rem;
    line-height: 2rem;
}

.minict_wrapper ul li:hover {
    background: #fcfcfc;
    color: #111;
}

.minict_wrapper ul li.minict_group {
    color: #444;
    background: #f6f6f6;
    font-weight: bold;
    cursor: default;
}

.minict_wrapper ul li.minict_empty {
    display: none;
    background: #fff !important;
    color: #bbb;
    text-align: center;
    font-size: 0.9rem;
    height: 3.5rem;
    line-height: 3.3rem;
}

.minict_wrapper ul li.selected {
    color: #819a9a;
    background: #f9fcff;
}

.minict_wrapper ul li.highlighted {
    color: #fff;
    background: #819a9a;
}

.minict_wrapper ul li.hidden {
    display: none;
}

.minict_wrapper.bubble {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
    background: -moz-linear-gradient(top, white 0%, #f9f9f9 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #f9f9f9));
    background: -webkit-linear-gradient(top, white 0%, #f9f9f9 100%);
    background: -o-linear-gradient(top, white 0%, #f9f9f9 100%);
    background: -ms-linear-gradient(top, white 0%, #f9f9f9 100%);
    background: linear-gradient(to bottom, white 0%, #f9f9f9 100%);
}

.minict_wrapper.bubble:hover {
    border-color: #dcdcdc;
}

.minict_wrapper.bubble:after {
    border-left: 1px solid #e5e5e5;
    -webkit-box-shadow: inset 1px 0px 0px 0px #fff;
    box-shadow: inset 1px 0px 0px 0px #fff;
    height: 2.05rem;
}

.minict_wrapper.bubble ul {
    top: 2.7rem;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    overflow: visible;
}

.minict_wrapper.bubble ul:before {
    position: absolute;
    top: -11px;
    left: 19px;
    content: ".";
    color: transparent;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 11px 11px 11px;
    border-color: transparent transparent #e5e5e5 transparent;
}

.minict_wrapper.bubble ul:after {
    position: absolute;
    top: -10px;
    left: 20px;
    content: ".";
    color: transparent;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
}

.minict_wrapper.bubble ul li.minict_first {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.minict_wrapper.bubble ul li.minict_last {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
